/* Responsive */
@media screen and (max-width: 1325px) {
  .header_container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1300px) {
  .logo img {
    width: 65px;
  }
}

@media screen and (max-width: 1294px) {
  .main_container {
    max-width: 1028px;
    padding: 0 20px;
  }
  .grid_container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media screen and (max-width: 1150px) {
  .nav_list {
    gap: 50px;
  }
  .logo img {
    width: 60px;
  }
}
@media screen and (max-width: 1130px) {
  .nav_list {
    gap: 40px;
  }
  .nav_list li a {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 1110px) {
  .nav_list {
    gap: 35px;
  }
  .nav_list li a {
    font-size: 1.6rem;
  }
  .logo img {
    width: 55px;
  }
}
@media screen and (max-width: 1080px) {
  .nav_list {
    gap: 30px;
  }
  .nav_list li a {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1048px) {
}
@media screen and (max-width: 1040px) {
  .contact_box {
    margin-bottom: 10px;
  }
  .grid_box_favorite {
    max-width: 920px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .favory_font_h2 {
    max-width: 920px;
  }
}

@media screen and (max-width: 1025px) {
  .nav_list {
    gap: 30px;
  }
}
@media screen and (max-width: 990px) {
  .nav_list {
    gap: 20px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 960px) {
  header {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
  }
  .head_slogan {
    margin-top: 10vh;
    font-size: 6rem;
  }
  .contact_container {
    margin-top: 15vh;
  }
  .p_container {
    margin-top: 15vh;
  }
  .nav_list {
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    height: 220px;
    row-gap: 30px;
    background: #737373;
    top: 11vh;
    right: -100%;
    padding-top: 30px;
    z-index: 9999;
    transition: all 0.3s;
  }
  .open_nav_list {
    right: 0;
  }

  .burger_menu {
    display: block;
    font-size: 2rem;
    color: var(--white);
    cursor: pointer;
  }

  .burger_menu {
    -webkit-tap-highlight-color: transparent;
  }
  .clicked:nth-child(1) {
    transform: rotate(45deg) translateY(10px);
    transition: all 0.3s;
  }
  .clicked:nth-child(2) {
    transform: scale(0.1);
    transition: all 0.3s;
  }
  .clicked:nth-child(3) {
    transform: rotate(135deg) translateY(10px);
    transition: all 0.3s;
  }

  .lang_menu button {
    font-size: 1.5rem;
  }
  .nav_list {
    gap: 30px;
  }
  .header_container .logo {
    width: 100px;
  }
  .nav_list li a {
    font-size: 1.6rem;
  }
  .basket_box {
    flex-direction: column;
  }
  .b_container h2 {
    font-size: 2.6rem;
    margin-bottom: 30px;
    padding-top: 100px;
  }
  .basket_colum_orders {
    width: 100%;
  }
  .right_side {
    display: block;
    margin-left: auto;
  }

  /* contact */
  .contact_content {
    flex-direction: column;
  }
  .contact_left {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  .contact_form {
    width: 100%;
    margin-top: 50px;
  }
  .contact_flex input {
    width: 100%;
  }
  .contact_flex label {
    width: 100%;
  }

  .main_container {
    max-width: 786px;
  }
  .category_checkbox {
    display: none;
  }
  .grid_container::before {
    display: none;
  }
  .category_choise_modal {
    display: flex;
  }
  .main_header {
    font-size: 2.8rem;
  }
  ._choise_modal_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 222px;
    height: auto;
    padding: 20px;
    position: absolute;
    right: 0;
    margin-top: 40px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 10;
  }
  .search_inp_products {
    /* max-width: 200px; */
    border: 1px solid #878787;
    height: 40px;
    /* border-radius: 6px; */
    padding: 0 5px;
  }
  .search_inp_products input {
    font-family: "Lato", sans-serif;
    height: 40px;
    font-size: 1.6rem;
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
  }
}
@media screen and (max-width: 920px) {
  .nav_list li {
    text-align: center;
    line-height: 20px;
  }
  .p_box {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .p_content {
    margin-left: 10px;
  }
}

@media screen and (max-width: 850px) {
  .grid_box_favorite {
    max-width: 730px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 30px;
  }
  .favory_font_h2 {
    max-width: 730px;
  }
}
#checkbox {
  display: none;
}
@media screen and (max-width: 786px) {
  .header_container {
    position: relative;
  }

  /*  delete here */

  .user_modal_hover button {
    width: 125px;
    height: 25px;
    font-size: 1.4rem;
  }
  .social_media_i {
    display: flex;
    align-items: center;
    margin-top: -15px;
    gap: 10px;
  }
  /* .social_media_icons {
    display: none;
  } */

  .head_slogan {
    font-size: 5.2rem;
  }

  /* footer */
  .aboutUs_information {
    flex-direction: column;
  }
  .aboutUs {
    max-width: 100%;
  }
  .flex_wrap {
    margin: 0 auto;
  }
  .aboutUs h4,
  .information h4,
  .follow_us h4 {
    font-size: 1.6rem;
  }
  .aboutUs p,
  .information p {
    font-size: 1.6rem;
  }
  /* Hamburger */
}

@media screen and (max-width: 730px) {
  .image-gallery-content {
    width: 450px;
  }

  .nav_list {
    width: 100%;
    opacity: 0.9;
    z-index: 9;
    transition: opacity 0.5s, transform 0.5s;
  }
  .priceness {
    width: 200px;
  }
  .main_container {
    max-width: 540px;
  }
  .grid_container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }
  .p_img {
    width: 300px;
    height: 450px;
  }
  .p_content .p_price {
    font-size: 2rem;
  }
}

@media screen and (max-width: 680px) {
  .p_content {
    gap: 10px;
  }
  .head_slogan {
    text-align: center;
  }
  .grid_box_favorite {
    max-width: 480px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .favory_font_h2 {
    max-width: 480px;
  }
  .address_payment_container {
    flex-direction: column;
  }
  .visit_cart_user {
    width: 100%;
  }
  .visit_cart_user h3 {
    width: 130px;
  }
}

@media screen and (max-width: 510px) {
  .image-gallery-content {
    width: 400px;
  }
  .contact_left {
    width: 100%;
    grid-template-columns: auto;
  }
  .contact_container h1 {
    font-size: 2.6rem;
    margin-bottom: 40px;
  }
  .contact_box h2 {
    font-size: 2rem;
  }
  .contact_form h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }
  .head_slogan {
    text-align: center;
  }
  .left_side_content {
    align-items: flex-start;
  }
  .category {
    margin-bottom: 10px;
  }
  .right_side {
    width: 280px;
  }
  .b_price {
    font-size: 1.6rem;
    font-weight: 600;
  }
}


@media screen and (max-width: 460px) {
  /* footer */
  .flex_wrap {
    flex-direction: column;
  }
  .information {
    width: 280px;
  }
  .information div {
    margin-top: 20px;
  }
  .follow_us h4 {
    display: none;
  }
  .aboutUs h4,
  .information h4 {
    margin-bottom: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 445px) {
  .left_side_content {
    flex-wrap: wrap;
  }
  .image-gallery-content {
    width: 360px;
  }
}

@media screen and (max-width: 400px) {
  .login_register_container {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .login_box button,
  .register_box button {
    font-size: 1.4rem;
    margin: 20px 0 20px;
  }
  .login_header,
  .register_header {
    font-size: 2.2rem;
  }
  .go_to_register {
    flex-direction: column;
    font-size: 1.4rem;
  }
  .go_to_login .link_text {
    font-size: 1.4rem;
  }
  .priceness {
    width: 150px;
  }
  .p_size {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .favory_font_h2 {
    font-size: 2rem;
  }
  .similar_head {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 370px) {
  .p_content {
    margin-left: 10px;
  }
  .title{
    font-size: 1.3rem;
  }
  .header_icons_container{
    gap: 14px;
  }
  .p_content h3{
    font-size: 2.3rem;
  }
  .basket{
    width: 250px;
  }
 
}
