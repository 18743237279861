:root {
  --white: #fff;
  --black: #000;
  --logo--black: #151515;
  --title: #222222;
  --footer--back: rgba(238, 238, 238, 0.4);
  --footer-p: #5f5f5f;
  --line: #d3d3d3;
  --select: #4e4e4e;
  --basket: #112131;
  --body: #fffdf8;
  --sb_bg_col: rgb(42, 63, 84);
  --sb_li_col: rgb(71, 113, 155);
  --fa_user_bg: #dadada;
  --user_col: rgb(255, 255, 129);
  --ad_pan_h_bg: #ededed;
  --column2_bg: #f7f7f7;
  --line: #112130;
  --basket--back: #fcfcfc;
}
/* #737373 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  background: var(--white);
  /* min-height: 100vh;
  position: relative;
  padding-bottom: 300px; */
}

header {
  background: #404040;
  box-shadow: 0px 1px 10px 0px rgba(181, 179, 180, 0.15);
}

.header_container {
  max-width: 1300px;
  min-height: 11vh;
  margin: 0 auto;
  /* padding: 0 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 100px;
}
.logo img {
  width: 70px;
}

.logo_style {
  font-family: "Clicker Script", cursive;
  font-size: 3rem;
  color: #474747;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.nav_list {
  display: flex;
  gap: 60px;
}

.nav_list li {
  list-style: none;
  position: relative;
}

.nav_list li a {
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 400;
  text-decoration: none;
  color: white;
  letter-spacing: 0.2rem;
}

.nav_list li a::after {
  content: "";
  position: absolute;
  width: 0;
  left: 0;
  bottom: -5px;
  height: 2px;
  background: var(--logo--black);
  transition: 0.5s;
}

.nav_list li a:hover::after {
  width: 100%;
}

.nav_list li a:hover {
  color: var(--logo--black);
}

.header_icons_container {
  display: flex;
  align-items: center;
  gap: 19px;
}
.header_icons_container i {
  font-size: 1.8rem;
  color: var(--white);
}

.user_icon {
  font-size: 1.8rem;
  cursor: pointer;
  position: relative;
}

.fa-circle-user {
  cursor: pointer;
}

.user_modal_hover {
  font-family: "Lato", sans-serif;
  position: absolute;
  width: 140px;
  background-color: var(--white);
  border: 1px solid #5e5e5e;
  /* top: 7vh; */
  z-index: 3;
  border-radius: 5px;
  transform: translateX(-50%);
  padding-top: 10px;
  display: none;
}

.user_modal_hover button {
  width: 120px;
  height: 19px;
  margin: 0 auto;
  background-color: #adadad;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.user_container_modals i {
  font-size: 2.1rem;
  cursor: pointer;
}

.user_modal_hover_i i {
  color: var(--footer-p);
}
.user_container_modals:hover .user_modal_hover {
  display: block;
}

.short_email_if_long {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loggedInUser_content,
.loggedInUser_content_name {
  display: flex;
  align-items: center;
  gap: 20;
  cursor: pointer;
  margin-bottom: 6px;
  padding-left: 10px;
}

.loggedInUser_content_name span {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loggedInUser_content {
  width: 100%;
}
.loggedInUser_content i {
  font-size: 1.3rem;
}
.loggedInUser_content span {
  margin-left: 6px;
}

.loggedInUser_content:hover {
  background-color: #bbbbbb;
}
.bag_icon {
  font-size: 1.8rem;
  cursor: pointer;
  position: relative;
}
.bag_icon svg {
  color: var(--white);
}

.basket_count {
  position: absolute;
  font-size: 11px;
  text-align: center;
  top: -2px;
  right: -7px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  background-color: #b73232;
  color: var(--white);
}

.social_media_icons {
  display: flex;
  gap: 15px;
}
.social_media_icons i {
  font-size: 2.1rem;
}

.social_media_i {
  display: none;
}
.lang_menu {
  display: flex;
  align-items: center;
}

.lang_menu button {
  border: none;
  background: none;
  color: var(--white);
  font-size: 1.7rem;
  cursor: pointer;
}
.lang_menu button:hover {
  color: var(--select);
}

.lang_menu span {
  color: var(--white);
  margin-left: 3px;
  margin-right: 3px;
  font-size: 1.8rem;
}

.burger_menu {
  display: none;
  position: relative;
  font-size: 1.2rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.burger_class {
  display: block;
  width: 30px;
  height: 2px;
  border-radius: 8px;
  background: var(--white);
}
.burger_class:nth-child(2) {
  margin: 5px 0;
}

.checkbox {
  display: none;
}

/* Hero */

.head_slogan {
  height: 89vh;
  font-family: "Bona Nova";
  font-size: 6.6rem;
  font-weight: 400;
  letter-spacing: 0.2em;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.slideStyle {
  height: 89vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: relative;
}
.slideStyle:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* main section boxes */

.main_header {
  font-family: "Poppins", sans-serif;
  font-size: 3.6rem;
  text-align: center;
  color: var(--title);
  font-weight: 400;
  margin-top: 50px;
}

.main_container {
  max-width: 1254px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  position: relative;
  margin-bottom: 100px;
}

.grid_container::before {
  position: absolute;
  content: "";
  top: 0;
  left: -20px;
  width: 2px;
  height: 100%;
  background-color: #e2e1e1;
}

.grid_container {
  max-width: 1064px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
  position: relative;
}
.grid_box {
  max-width: 242px;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  min-height: 265px;
  /* max-height: 365px; */
}

.grid_box p,
.grid_box span {
  font-family: "Lato", sans-serif;
}
.favory_font_h2 {
  font-family: "Lato", sans-serif;
  font-size: 2.8rem;
  max-width: 1300px;
  margin: 25px auto;
  padding: 0 20px;
}

/* bura */
.product_images img {
  transition: 0.3s linear !important;
  width: 100%;
}
.similar_product_box_size {
  /* max-height: 380px; */
  min-height: 230px;
}

.product_images {
  overflow: hidden;
  width: 100%;
}
.product_images img:hover {
  transform: scale(1.03);
}

.title {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--title);
  margin-top: 8px;
  margin-bottom: 8px;
}

.grid_box_content {
  padding: 0 0 5px 8px;
  height: 70px;
}

.heart_for_favorite {
  background: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}

.heart_for_favorite i {
  display: flex;
  justify-content: center;
  line-height: 40px;
  color: var(--footer-p);
}

.heart_for_favorite i {
  font-size: 2rem;
}

.heart_for_favorite i:hover {
  color: var(--footer-p);
}

/* Favorite */

.grid_box_favorite {
  max-width: 1300px;
  margin: 68px auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 30px;
  cursor: pointer;
}
.when_notmy_favourites {
  font-family: "Lato", sans-serif;
  margin: 50px 0;
  font-size: 2rem;
}
.grid_box_favorite_cart {
  max-width: 216px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  position: relative;
}

.del_for_myfavorite {
  background: var(--white);
  width: 40px;
  height: 40px;
  border: 1px solid rgb(214, 212, 212);
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: 5px;
  cursor: pointer;
  transition: 0.2s linear;
  z-index: 99;
}
.fa-xmark {
  font-size: 1.05em;
  padding: 10px;
  color: rgb(151, 149, 149);
  transition: 0.2s linear;
}

.del_for_myfavorite:hover {
  border: 1px solid brown;
}
.fa-xmark:hover {
  color: brown;
}

.grid_box_favorite_cart img {
  width: 100%;
}

.price,
.price_discount {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 16px;
  color: var(--title);
  margin-right: 5px;
}

.price_del {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 16px;
  color: #878787;
  margin-right: 5px;
}

.select_size {
  margin: 17px 0;
}

.select_size select {
  width: 100%;
  height: 44px;
  color: var(--select);
  border: 0.5px solid #e2e2e2;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
  padding: 0 15px;
  outline: none;
}

.basket {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  padding: 5px 16px 5px 10px;
  background: #7e9496;
  box-shadow: 0px 1px 4px rgba(56, 57, 67, 0.25);
  cursor: pointer;
}
.basket svg {
  width: 20px;
  height: 20px;
}

.add_to_card {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  background: none;
  border: none;
  color: var(--white);
  margin-left: 10px;
  cursor: pointer;
}
.heart_add_to_basket {
  display: flex;
  align-items: center;
  gap: 10px;
}
.heart_add_to_basket i {
  font-size: 3rem;
  cursor: pointer;
}

/* My order Items  */
.orderitems_container {
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid var(--fa_user_bg);
  border-radius: 8px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.orderitems_container_head {
  display: flex;
  background: #fafafa;
  border-radius: 8px 8px 0 0;
}
.orderitems_container_content {
  display: flex;
  gap: 10px;
  min-width: 350px;
  border: 1px solid var(--fa_user_bg);
  padding: 5px;
  border-radius: 8px;
}
.oc_con_img {
  width: 70px;
  height: 105px;
  border-radius: 3px;
}
.oc_con_img img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid var(--fa_user_bg);
}
.all_h4_soan_p {
  font-family: "Josefin Sans", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 8px;
}
.under_category_p {
  display: flex;
  gap: 5px;
  font-size: 1.3rem;
  color: #878787;
}
.goto_singlepage_gromoritems {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  width: 110px;
  height: 30px;
  border: none;
  font-weight: 500;
  border-radius: 8px;
  background: var(--basket);
  box-shadow: 0px 1px 4px 0px rgba(56, 57, 67, 0.25);
  cursor: pointer;
  margin-top: 6px;
}
.goto_singlepage_gromoritems:hover {
  transform: scale(1.05);
}
.goto_singlepage_gromoritems a {
  font-family: "Montserrat", sans-serif;
  color: var(--white);
  font-size: 1.4rem;
}
/*START TEST  */

/* END TEST */
.grid_container_orderitmes {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 10px;
  padding: 50px 10px;
}

/* footer */

footer {
  background-color: var(--footer--back);
  margin-top: 30px;
  padding: 25px 0;
  /* margin-bottom: 0;
  position: absolute;
  bottom: 0; */
  width: 100%;
}

.aboutUs_information {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  gap: 30px 20px;
}

.flex_wrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.aboutUs {
  max-width: 560px;
}

.information {
  width: 300px;
}

.follow_us {
  width: 100px;
}

.information div {
  display: flex;
  align-items: center;
  margin-top: 44px;
}
.email a {
  color: var(--footer-p);
}
.email a:hover {
  color: var(--basket);
}

.social_media {
  display: flex;
  justify-content: center;
  gap: 19px;
}

.aboutUs h4,
.information h4,
.follow_us h4 {
  font-family: "Merriweather";
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 18px;
  color: var(--title);
  margin-bottom: 36px;
}

.information h4 {
  text-align: left;
}

.aboutUs p {
  /* font-family: "Lato", sans-serif; */
  text-align: center;
  font-weight: 300;
  font-size: 2rem;
  line-height: 30px;
  text-align: justify;
  color: var(--footer-p);
}
.phone_number a {
  color: var(--footer-p);
}
.phone_number a:hover {
  color: var(--basket);
}
.information p {
  /* font-family: "Lato", sans-serif; */
  font-weight: 300;
  font-size: 1.9rem;
  line-height: 22px;
  color: var(--footer-p);
  margin-left: 10px;
}

/* Login & Register */

.login_register_container {
  max-width: 400px;
  margin: 95px auto 73px;
  border-radius: 20px;
  box-shadow: 0px 0px 52px 15px rgba(221, 221, 221, 0.2);
}

.login_header,
.register_header {
  font-family: "Merriweather", serif;
  font-size: 3.2rem;
  text-align: center;
  color: var(--title);
  font-weight: 400;
  cursor: pointer;
}

.login_box,
.register_box {
  padding: 20px 30px 50px 30px;
}
.eye_psw {
  display: flex;
  position: relative;
}
.eye_psw i {
  position: absolute;
  top: 40px;
  right: 5px;
  cursor: pointer;
}
.login_box input,
.register_box input {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #a3a3a3;
  margin-top: 30px;
}

.error {
  font-family: "Merriweather", serif;
  font-size: 1.2rem;
  color: #f53e6a;
  display: block;
  text-align: center;
  margin-top: 10px;
}

.login_box button,
.register_box button {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 48px;
  border: none;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--white);
  border-radius: 8px;
  background: var(--basket);
  box-shadow: 0px 1px 4px 0px rgba(56, 57, 67, 0.25);
  padding: 5px 13px;
  margin: 30px 0 20px;
}

.go_to_register,
.go_to_login {
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link_text {
  color: blue;
  text-decoration-color: var(--basket);
  margin-left: 5px;
}

/* Product */

.p_container {
  max-width: 1300px;
  margin: 80px auto;
  font-family: "Montserrat", sans-serif;
}

.p_box {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  align-items: flex-start;
}

.prod_img_container {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prod_img_btn {
  color: var(--footer-p);
  cursor: pointer;
}

.class_of_images {
  width: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.alotof_img_box {
  display: flex;
  gap: 5px;
  transition: transform 0.3s ease-in-out;
}
._img_boxs img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
._img_boxs {
  cursor: pointer;
  width: 60px;
  height: 90px;
  border-radius: 4px;
}
/* Product Gallery Slide START */

.images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 500px;
}

.image-gallery-slide.image-gallery-center {
  min-height: 450px;
}
/* React-image-gallery */
@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #7e9496 !important;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #7e9496 !important;
  }
}

.image-gallery-thumbnails {
  max-width: 500px;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 4px solid #7e9496 !important;
}

/* slides */

.p_img {
  width: 350px;
  height: 480px;
  position: relative;
}

.p_img img {
  width: 100%;
  height: 100%;
}

.left_navigation,
.right_navigation {
  position: absolute;
  top: 45%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: gray;
  cursor: pointer;
}

.left_navigation {
  left: 10px;
}

.right_navigation {
  right: 10px;
}
.left_navigation:hover,
.right_navigation:hover {
  color: brown;
}

.p_content {
  max-width: 466px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 50px;
}

.heart {
  border: 1px solid #c6c6c6;
  font-size: 3rem;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* .content_text {
  text-align: justify;
} */

.p_content h3 {
  font-size: 2.8rem;
}

.p_content .p_price {
  font-size: 2.4rem;
  color: var(--basket);
  font-weight: 500;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 8px;
}

.p_color,
.p_size {
  display: flex;
  gap: 10px;
  list-style: none;
  margin-top: 8px;
}

.p_color li,
.circle_of_choose {
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px 0px;
}
.circle_of_choose {
  margin-left: 10px;
  width: 25px;
  height: 25px;
}

.color_of_circle {
  display: flex;
}

.p_size li {
  min-width: 48px;
  height: 32px;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--black);
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 0 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_to {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  background: none;
  border: none;
  color: var(--white);
}

/* Basket component */

.b_container {
  max-width: 1170px;
  margin: 30px auto 195px;
  padding: 0 16px;
  background: var(--white);
}

.b_container h2 {
  font-family: "Merriweather", serif;
  font-size: 3.2rem;
  color: var(--title);
  font-weight: 400;
  margin-bottom: 50px;
}

.basket_box {
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--white);
  gap: 20px;
}
.basket_colum_orders {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left_side {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 8px;
}
.left_side img {
  border-radius: 8px 0 0 8px;
}

.b_img {
  width: 170px;
  height: 180px;
}

.b_img img {
  width: 100%;
  height: 100%;
}

.left_side_content {
  font-family: "Lato", sans-serif;
  width: 100%;
  min-height: 115px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 15px;
}

.category {
  font-size: 1.8rem;
  color: var(--title);
  font-weight: 600;
  margin-bottom: 30px;
}

.color_size {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.color_size p {
  color: var(--select);
  font-size: 1.4rem;
  font-weight: 600;
  /* display: flex;
    justify-content: space-between; */
}

.color_size span {
  margin-left: 15px;
}

.b_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.b_buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.b_buttons span {
  color: var(--title);
  font-size: 1.6rem;
  font-weight: 500;
}

.priceness {
  width: 270px;
  display: flex;
  justify-content: space-between;
}

.b_price {
  color: var(--title);
  font-size: 1.8rem;
  font-weight: 600;
}

.trash {
  cursor: pointer;
}
.trash svg:hover {
  color: var(--footer-p);
  transition: 0.2s linear;
}

.right_side {
  width: 311px;
  padding: 20px 15px;
  border-radius: 7px;
  border: 0.2px solid #d2d2d2;
  background: #fffefd;
  box-shadow: 0px 0px 10px -6px #686868;
}

.right_side_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right_side_content p {
  font-family: "Lato", sans-serif;
  color: var(--select);
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.right_side_content p:nth-child(2) {
  border-bottom: 1px solid var(--line);
  padding-bottom: 15px;
}

.right_side_content button,
.save_userInfo_btn,
.change_user_info_btn {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 44px;
  border: none;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--white);
  border-radius: 8px;
  background: var(--basket);
  box-shadow: 0px 1px 4px 0px rgba(56, 57, 67, 0.25);
  cursor: pointer;
}

/* Contact component */

.contact_container {
  font-family: "Montserrat", sans-serif;
  max-width: 1300px;
  margin: 72px auto 105px;
  padding: 0 20px;
}

.contact_container h1 {
  font-size: 4rem;
  text-align: center;
  color: var(--title);
  margin-bottom: 70px;
}

.contact_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact_left {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.contact_box {
  display: flex;
  min-width: 220px;
  margin-bottom: 50px;
}

.box_icon {
  font-size: 3rem;
  margin-right: 20px;
}

.contact_box:nth-of-type(1) .box_icon {
  color: #c17c0d;
}

.contact_box:nth-of-type(2) .box_icon {
  color: #f53e6a;
}

.contact_box h2 {
  margin-bottom: 25px;
}

.contact_form h2 {
  margin-bottom: 40px;
}

.contact_form input {
  width: 255px;
  height: 45px;
  font-size: 1.6rem;
  border: 1px solid #d1d5d9;
  border-radius: 4px;
  padding: 8px 16px;
  outline: none;
}

.contact_flex {
  display: flex;
  gap: 30px;
  margin-bottom: 25px;
}

.contact_form textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  width: 100%;
  outline: none;
  resize: none;
  border: 1px solid #d1d5d9;
  border-radius: 4px;
  padding: 8px 16px;
}

.contact_btn {
  display: block;
  margin-left: auto;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  height: 48px;
  border: none;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--white);
  border-radius: 4px;
  background: var(--basket);
  box-shadow: 0px 1px 4px 0px rgba(56, 57, 67, 0.25);
  padding: 5px 30px;
  cursor: pointer;
}

/* Information of User */
.address_payment_container {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 50px;
  padding: 0 20px;
}
.head_of_address_paymenth2 {
  font-family: "Josefin Sans", sans-serif;
  max-width: 1260px;
  margin: 30px auto;
  padding: 0 20px;
}
.name_lastName,
.email_phone {
  display: flex;
  gap: 30px;
  margin-bottom: 25px;
}
.user_information input {
  width: 255px;
  height: 45px;
  font-size: 1.6rem;
  border: 1px solid #d1d5d9;
  border-radius: 4px;
  padding: 8px 16px;
  outline: none;
}
.user_information textarea {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.6rem;
  width: 100%;
  outline: none;
  resize: none;
  border: 1px solid #d1d5d9;
  border-radius: 4px;
  padding: 8px 16px;
}

.newadress_create {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;
  height: 276px;
  cursor: pointer;
}
.iplus_span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--column2_bg);
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.iplus_span:hover {
  transform: scale(1.05);
}
.iplus_span i {
  font-size: 5rem;
  color: var(--basket);
}
.iplus_span span {
  font-size: 2rem;
  font-family: "Josefin Sans", sans-serif;
  color: rgb(37, 37, 37);
}

.visit_cart_user {
  font-family: "Lato", sans-serif;
  width: 340px;
  padding: 20px;
  border-radius: 8px;
  border: 0.2px solid #d2d2d2;
  background: #fffefd;
  box-shadow: 0px 0px 10px -6px #686868;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.visit_cart_user_inorderitems {
  font-family: "Josefin Sans", sans-serif;
  border-radius: 8px;
  border: 0.2px solid #d2d2d2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 35px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
}

.visit_cart_user h3,
.visit_cart_user_inorderitems h3 {
  color: var(--footer-p);
  width: 105px;
}

.h3_span_inuser {
  display: flex;
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(102, 102, 102, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  /* background: linear-gradient(to right, #0d3c61, #02b474); */
}

.payment {
  background: #f8f8f8;
  max-width: 360px;
  height: auto;
  padding: 35px;
  padding-top: 70px;
  border-radius: 5px;
  position: relative;
}

.payment h2 {
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 40px;
  color: #0d3c61;
}

.form .label {
  display: block;
  color: #555555;
  margin-bottom: 6px;
}

.input {
  padding: 13px 0px 13px 25px;
  width: 100%;
  text-align: center;
  border: 2px solid #dddddd;
  border-radius: 5px;
  letter-spacing: 1px;
  word-spacing: 3px;
  outline: none;
  font-size: 16px;
  color: #555555;
}

.card-grp {
  display: flex;
  justify-content: space-between;
}

.card-item {
  width: 48%;
}

.space {
  margin-bottom: 20px;
}

.icon-relative {
  position: relative;
}

.icon-relative .fas,
.icon-relative .far {
  position: absolute;
  bottom: 12px;
  left: 15px;
  font-size: 20px;
  color: #555555;
}

.btn {
  /* font-family: "Lato", sans-serif; */
  margin-top: 40px;
  background: #2d5b99;
  padding: 12px;
  text-align: center;
  color: #f8f8f8;
  border-radius: 5px;
  cursor: pointer;
}

.payment-logo {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 150px;
  z-index: 10;
}

.payment-logo img {
  width: 100%;
}

@media screen and (max-width: 420px) {
  .card-grp {
    flex-direction: column;
  }
  .card-item {
    width: 100%;
    margin-bottom: 20px;
  }
  .btn {
    margin-top: 20px;
  }
}

.btn-scrollTop {
  border: none;
  width: 45px;
  height: 45px;
  background-color: #b3b3b3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  display: none;
}
.btn-scrollTop i {
  font-size: 2rem;
}

/* Async button REGISTER */
.loader_reg {
  width: 40px;
  height: 40px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
/* Async button payment   */
.form_lodarer_wrapper {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader_payment {
  width: 70px;
  height: 70px;
  border: 5px solid #26db9f;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Async Login animation  */
.lds-dual-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  background-color: var(--body);
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #d3a50d;
  border-color: #d3a50d transparent #d3a50d transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Async contact form */
.loader,
.loader_user_icon {
  width: 30px;
  height: 30px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}
.loader_user_icon {
  width: 19px;
  height: 19px;
  border: 3px solid #ffd4d4;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Product cart box swiper slider */

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  -webkit-tap-highlight-color: transparent;
}
/* emine at */

.similar_products_container {
  max-width: 1260px;
  margin: 80px auto 0;
  padding: 0 20px;
}

.similar_head {
  font-family: "Montserrat", sans-serif;
  font-size: 2.8rem;
  color: var(--title);
  font-weight: 600;
  margin-bottom: 20px;
}

.similar_product_box {
  min-width: 170px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 5px 0;
  /* min-height: 310px;
  max-height: 460px; */
}

.similar_product_box p,
.similar_product_box span,
.myorder_empty_array {
  font-family: "Josefin Sans", sans-serif;
}

.similar_product_box img {
  width: 100%;
}

.similar_product_content {
  padding: 0 0 5px 8px;
}

.mySwiper .swiper-button-prev {
  color: var(--select);
  transform: scale(0.7);
  left: 10px;
}

.mySwiper .swiper-button-next {
  color: var(--select);
  transform: scale(0.7);
  right: 10px;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

/* .slick-prev {
  left: -52px !important;
}
.slick-next{
  right: -10px !important;
}
.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  color: gray;
  font-size: 40px;
} */

/* User Info Modal  */

.info_modal_container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(102, 102, 102, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.info_modal_card {
  font-family: "Montserrat", sans-serif;
  width: 400px;
  border-radius: 6px;
  background: var(--white);
}

.info_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px 0px 14px 15px;
  border-bottom: solid 1px #e6e6e6;
}

.info_modal_head {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(255, 44, 44);
  padding-right: 5px;
}

.info_xmark {
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 390px;
}
.info_xmark i {
  color: #000;
}
.info_xmarkx {
  font-size: 2rem;
  cursor: pointer;
}

.info_inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 30px;
}

.info_inputs input {
  width: 100%;
  height: 35px;
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid #a3a3a3;
  outline: none;
}

.info_inputs textarea {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 50px;
  resize: none;
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid #a3a3a3;
  outline: none;
}

.info_inputs button {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 42px;
  border: none;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--white);
  border-radius: 8px;
  background: var(--basket);
  box-shadow: 0px 1px 4px 0px rgba(56, 57, 67, 0.25);
  padding: 5px 13px;
  cursor: pointer;
}
/* Category btn in HOME page */
.category_choise_modal {
  /* width: 200px; */
  height: 40px;
  font-weight: 600;
  border: 1px solid #878787;
  /* border-radius: 6px; */
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  padding: 5px 15px;
}
._choise_modal_list {
  font-family: "Josefin Sans", sans-serif;
  top: 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
  padding: 20px 20px 0 0;
  z-index: 10;
}

.category_choise_modal p {
  font-family: "Josefin Sans", sans-serif;
}

._choise_modal_list p {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 15px;
}

._choise_modal_list input[type="checkbox"] {
  accent-color: var(--logo--black);
}

._choise_modal_list input {
  width: 13px;
  height: 22px;
  margin-right: 5px;
  cursor: pointer;
}

._choise_modal_list label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1.4rem;
}
/* Search inp in HOME page */
.search_and_catecory {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 30px 0 20px;
}
.search_inp_products {
  max-width: 100%;
  border-bottom: 1px solid #878787;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.seach_and_cat_cont {
  max-width: 140px;
}

.search_inp_products input {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 5px 2px 5px 5px;
}
.open_search_list {
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 10px;
  padding: 5px 2px 0 5px;
}
.open_search_list:hover {
  background: #7e9496;
  cursor: pointer;
}

/* Loading css  */
.container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 #7e9496;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #7e9496;
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #7e9496;
  }
  50% {
    box-shadow: 0 0 0 #7e9496;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #7e9496;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}

/* React-share icons style */
.react-share__ShareButton svg {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.social_media_share {
  display: flex;
  gap: 5px;
}

/* Pop Up style */

.pup_up_for_change_pass {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pup_up_for_change_pass_content {
  background-color: #ffffff;
  box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
  width: 400px;
  height: 300px;
  /* height: 400px; */
  border-radius: 5px;
  overflow: auto;
  padding: 10px 15px;
  position: relative;
}
.p_inp_pop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 35px;
}
.p_inp_pop button,
.forget_password_question_btn {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 48px;
  border: none;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--white);
  border-radius: 8px;
  background: var(--basket);
  box-shadow: 0px 1px 4px 0px rgba(56, 57, 67, 0.25);
  padding: 5px 13px;
  margin: 30px 0 20px;
  cursor: pointer;
}

.forget_password_question_btn {
  border: none;
  background: none;
  color: blue;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
.p_inp_pop input {
  /* outline: none; */
  height: 30px;
  border: 2px solid #9f9f9f;
  border-radius: 5px;
  font-size: 1.7rem;
  padding-left: 5px;
}
.p_inp_pop_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.p_inp_pop_flex {
  font-size: 1.8rem;
}
.close_btn {
  text-align: right;
  margin-bottom: 8px;
  cursor: pointer;
}
.close_btn:hover {
  color: rgb(156, 156, 58);
}

/* FAQ Container design N2 */
.accordion {
  list-style-type: none;
  margin: 16px;
  padding: 16px;
  background-color: #f7f7f7;
  border-radius: 7px;
  max-width: 870px;
  margin: 0 auto;
}

.accordion_item {
  margin-bottom: 16px;
  background-color: var(--white);
  border-radius: 7px;
}
.accordion_head {
  font-family: "Josefin Sans", sans-serif;
  position: relative;
  width: 100%;
  padding: 20px 60px 20px 30px;
  background-color: var(--white);
  border: 0;
  border-radius: 7px;
  font-size: 2.2rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion_collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}
.accordion_collapse.open {
  height: auto;
}
.accordion_body {
  font-family: "Josefin Sans", sans-serif;
  padding: 30px 60px 30px 30px;
  background-color: #f1f1f1;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 2rem;
  color: #393939;
}
.accordion_head svg {
  transition: transform 0.3s ease-in-out; /* применяем переход к свойству transform */
}
.accordion_head svg.active {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
  color: #438f76;
}


.payment_btns{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.info_modal_card_taksit select{
  padding: 10px;
  border: none;
  background-color: var(--ad_pan_h_bg);
  box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
  border-radius: 8px;
  font-size: 1.8rem;
  outline: none;
}

.close_taksit_modal_icon{
  font-size: 30px;
  margin-top: 10px;
  margin-left: auto;
  display: block;
  margin-right: 10px;
  cursor: pointer;
}

.approved_page_container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  gap: 30px;
}
.approved_page_container h2{
  font-size: 3rem;
}
.approved_page_container img{
  width: 200px;
}
.approved_page_container button{
  padding: 10px;
  background-color: var(--white);
  color: var(--basket);
  font-size: 2rem;
  cursor: pointer;
  border: 2px solid var(--basket);
  outline: none;
  border-radius: 8px;
  transition: .5s;
}
.approved_page_container button:hover{
  background-color: var(--basket);
  color: var(--white);
}
/* Taksit price */
.installment_month{
  line-height: 26px;
  font-size: 1.8rem;
}